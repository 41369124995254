/* ------------------------------------------------------------------- */
/* Color #f91942 - rgb(249, 25, 66)
---------------------------------------------------------------------- */
#booking-date-range span:after,
.time-slot label:hover span,
.daterangepicker td.in-range,
.time-slot input ~ label:hover,
.booking-estimated-cost span,
.time-slot label:hover span {
    color: #f91942;
}


.icon-box-v3:hover .ibv3-icon {
    background:  #F82246;
    box-shadow:  0 3px 8px #F8224650;
}

.icon-box-v3 .ibv3-icon i {
    color:  #F82246;
}

.icon-box-v3 .ibv3-icon i,
.icon-box-v3 .ibv3-icon svg g,
.icon-box-v3 .ibv3-icon svg circle,
.icon-box-v3 .ibv3-icon svg rect,
.icon-box-v3 .ibv3-icon svg path {
    fill:  #F82246;
}

.home-search-slide h3 a:before,
.home-search-slide h3 strong:before {
    background: #f91942;
}

.ibv3-icon {
    background:  #f9194210;
}
.daterangepicker td.in-range,
.time-slot input ~ label:hover {
    background-color: rgba(249, 25, 66, 0.08);
}

.daterangepicker .ranges li.active,
.day-slot-headline,
.add-slot-btn button:hover,
.daterangepicker td.available:hover, .daterangepicker th.available:hover,
.time-slot input:checked ~ label,
.daterangepicker td.active, .daterangepicker td.active:hover,
.daterangepicker .drp-buttons button.applyBtn {
    background-color: #f91942;
}

.comment-by-listing a:hover,
.browse-all-user-listings a i,
.hosted-by-title h4 a:hover,
.style-2 .trigger.active a,
.style-2 .ui-accordion .ui-accordion-header-active:hover,
.style-2 .ui-accordion .ui-accordion-header-active,
#posts-nav li a:hover,
.plan.featured .listing-badge,
.post-content h3 a:hover,
.add-review-photos i,
.show-more-button i,
.listing-details-sidebar li a,
.star-rating .rating-counter a:hover,
.more-search-options-trigger:after,
.header-widget .sign-in:hover,
#footer a,
#footer .footer-links li a:hover,
#navigation.style-1 .current,
#navigation.style-1 ul li:hover a,
.user-menu.active .user-name:after,
.user-menu:hover .user-name:after,
.user-menu.active .user-name,
.user-menu:hover .user-name,
.main-search-input-item.location a:hover,
.chosen-container .chosen-results li.highlighted,
.input-with-icon.location a i:hover,
.sort-by .chosen-container-single .chosen-single div:after,
.sort-by .chosen-container-single .chosen-default,
.panel-dropdown a:after,
.post-content a.read-more,
.post-meta li a:hover,
.widget-text h5 a:hover,
.about-author a,
a.button.border.white:hover,
.icon-box-2 i,
a.button.border,
.style-2 .ui-accordion .ui-accordion-header:hover,
.style-2 .trigger a:hover ,
.plan.featured .listing-badges .featured,
.list-4 li:before,
.list-3 li:before,
.list-2 li:before,
.list-1 li:before,
.info-box h4,
.testimonial-carousel .slick-slide.slick-active .testimonial:before,
.sign-in-form .tabs-nav li a:hover,
.sign-in-form .tabs-nav li.active a,
.lost_password:hover a,
#top-bar .social-icons li a:hover i,
.listing-share .social-icons li a:hover i,
.agent .social-icons li a:hover i,
#footer .social-icons li a:hover i,
.headline span i {
    color: #f91942;
}

.daterangepicker td.active.end-date.in-range.available,
.qtyTotal,
.mm-menu em.mm-counter,
.option-set li a.selected,
.category-small-box:hover,
.pricing-list-container h4:after,
#backtotop a,
.chosen-container-multi .chosen-choices li.search-choice,
.select-options li:hover,
button.panel-apply,
.layout-switcher a:hover,
.listing-features.checkboxes li:before,
.comment-by a.reply:hover,
.add-review-photos:hover,
.office-address h3:after,
.post-img:before,
button.button,
input[type="button"],
input[type="submit"],
a.button,
a.button.border:hover,
table.basic-table th,
.plan.featured .plan-price,
mark.color,
.style-4 .tabs-nav li.active a,
.style-5 .tabs-nav li.active a,
.dashboard-list-box .button.gray:hover,
.change-photo-btn:hover,
.dashboard-list-box  a.rate-review:hover,
input:checked + .slider,
.add-pricing-submenu.button:hover,
.add-pricing-list-item.button:hover,
.custom-zoom-in:hover,
.custom-zoom-out:hover,
#geoLocation:hover,
#streetView:hover,
#scrollEnabling:hover,
#scrollEnabling.enabled,
#mapnav-buttons a:hover,
#sign-in-dialog .mfp-close:hover,
#small-dialog .mfp-close:hover {
    background-color: #f91942;
}


.rangeslider__fill,
span.blog-item-tag ,
.testimonial-carousel .slick-slide.slick-active .testimonial-box,
.listing-item-container.list-layout span.tag,
.tip,
.mfp-arrow:hover {
    background: #f91942;
}


.rangeslider__handle { border-color: #f91942; }

.layout-switcher a.active {
    color: #f91942;
    border-color: #f91942;
}

#titlebar.listing-titlebar span.listing-tag {
    border-color: #f91942;
    color: #f91942;
}


.listing-slider-small .slick-next:hover,
.listing-slider-small .slick-prev:hover,
.listing-slider .slick-next:hover,
.listing-slider .slick-prev:hover {
    background-color: #f91942;
}


.listing-nav-container.cloned .listing-nav li:first-child a.active,
.listing-nav-container.cloned .listing-nav li:first-child a:hover,
.listing-nav li:first-child a,
.listing-nav li a.active,
.listing-nav li a:hover {
    border-color: #f91942;
    color: #f91942;
}

.pricing-list-container h4 {
    color: #f91942;
    border-color: #f91942;
}

.sidebar-textbox ul.contact-details li a { color: #f91942; }

a.button.border {
    color: #f91942;
    border-color: #f91942;
}

.trigger.active a,
.ui-accordion .ui-accordion-header-active:hover,
.ui-accordion .ui-accordion-header-active {
    background-color: #f91942;
    border-color: #f91942;
}

.numbered.color ol > li::before {
    border-color: #f91942;;
    color: #f91942;
}

.numbered.color.filled ol > li::before {
    border-color: #f91942;
    background-color: #f91942;
}

.info-box {
    border-top: 2px solid #f91942;
    background: linear-gradient(to bottom, rgba(255,255,255,0.98), rgba(255,255,255,0.95));
    background-color: #f91942;
    color: #f91942;
}

.info-box.no-border {
    background: linear-gradient(to bottom, rgba(255,255,255,0.96), rgba(255,255,255,0.93));
    background-color: #f91942;
}

.tabs-nav li a:hover { border-color: #f91942; }

.tabs-nav li a:hover,
.tabs-nav li.active a {
    border-color: #f91942;
    color: #f91942;
}

.style-3 .tabs-nav li a:hover,
.style-3 .tabs-nav li.active a {
    border-color: #f91942;
    background-color: #f91942;
}

.checkboxes input[type=checkbox]:checked + label:before {
    background-color: #f91942;
    border-color: #f91942;
}

.listing-item-container.compact .listing-item-content span.tag { background-color: #f91942; }

.dashboard-nav ul li.active,
.dashboard-nav ul li:hover { border-color: #f91942; }

.dashboard-list-box .comment-by-listing a:hover { color: #f91942; }

.opening-day:hover h5 { color: #f91942 !important; }

.map-box h4 a:hover { color: #f91942; }
.infoBox-close:hover {
    background-color: #f91942;
    -webkit-text-stroke: 1px #f91942;
}

.cluster-visible {
    background-color: #f91942;
}

.cluster-visible:before {
    border: 7px solid #f91942;
    box-shadow: inset 0 0 0 4px #f91942;
}

.marker-arrow {
    border-color: #f91942 transparent transparent;
}

.face.front {
    border-color: #f91942;
    color: #f91942;
}

.face.back {
    background: #f91942;
    border-color: #f91942;
}

.custom-zoom-in:hover:before,
.custom-zoom-out:hover:before  { -webkit-text-stroke: 1px #f91942;  }

.category-box-btn:hover {
    background-color: #f91942;
    border-color: #f91942;
} 

.message-bubble.me .message-text {
    color: #f91942;
    background-color: rgba(249,25,66,0.05);
}

.message-bubble.me .message-text:before {
    color: rgba(249,25,66,0.05);
}

.opening-hours.summary li:hover,
.opening-hours.summary li.total-costs span { color: #f91742; }
.payment-tab-trigger > input:checked ~ label::before { border-color: #f91742; }
.payment-tab-trigger > input:checked ~ label::after { background-color: #f91742; }

#navigation.style-1 .current,
#navigation.style-1 ul li:hover a,
body #navigation.style-1 ul li:hover ul li:hover a,
body #navigation.style-1 ul li:hover ul li:hover li:hover a,
body #navigation.style-1 ul li:hover ul li:hover li:hover li:hover a,
body #navigation.style-1 ul ul li:hover a:after,
body #navigation.style-1 ul ul li:hover ul li a:hover,
body .user-menu ul li a:hover {
    color: #f91942
}

#navigation.style-1>ul>li:hover>a,
#navigation.style-1>ul>li>a.current {
    background: rgba(249, 25, 66, .06);
    color: #f91942
}

.category-box:hover .category-box-content span,
.radio input[type=radio]+label .radio-label:after,
.uploadButton .uploadButton-button:hover {
    background-color: #f91942
}

.headline-with-separator:after,
.listing-item-container.list-layout span.tag,
.listing-item-content span.tag,
.transparent-header #header:not(.cloned) #navigation.style-1>ul>li:hover>a,
.transparent-header #header:not(.cloned) #navigation.style-1>ul>li>a.current,
.transparent-header #header:not(.cloned) .header-widget .button.border:hover,
.transparent-header #header:not(.cloned) .header-widget .button:hover {
    background: #6a93f5
}

.radio input[type=radio]:checked+label .radio-label {
    background-color: #f91942;
    border-color: #f91942
}

.uploadButton .uploadButton-button:hover {
    color: #fff
}

.category-small-box i,
.uploadButton .uploadButton-button:before {
    color: #f91942
}

.daterangepicker td.in-range,
.time-slot input~label:hover {
    background-color: rgba(249, 25, 66, .08)
}

section.fullwidth .icon-box-2 i {
    background-color: rgba(249, 25, 66, .07)
}

.slick-current .testimonial-author h4 span {
    background: rgba(249, 25, 66, .06);
    color: #f91942
}