#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}
.card.h526px{
    height: 557px  ;
}
.inline{
    display: inline !important;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.5s;
    width: 100%;
    height: 481px  ;
    background-color: #fff;
    border-radius: 48px;
}
.card2 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 1.3s;
    width: 100%;
    height: 84px  !important;
    background-color: #fff;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    /* border-radius: 48px; */
}
.card2:hover{
    box-shadow: 0 10px 27px 0 rgb(0 0 0 / 29%);
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.containera {
    padding: 2px 16px;
    margin-top: 17px;
}

.image1 {
    height: 55%;
    position: relative;
    top: 0;
    left: 0;

}
.image1.w100.h36 {
    width: 100%; height: 36pc;

}
.image3.l0{
    left: 0px;
}
.sqimage.ml27.w30
{
    margin-left: 27px; width: 30px;
}


.image2 {
    position: absolute;
    top: 203px;
    left: 138px;
    border-radius: 67px;
    width: 87px !important;
    height: 87px;
    /* margin-left: 121px; */
}
.image3{
    position: absolute;
    top: 3px;
    left: 20px;
    margin-top: 8px;
    border-radius: 50px;
    width: 60px !important;
    height: 60px;
    /* margin-left: 0px; */
}
.image4{
    top: -17px !important;
    
    /* margin-left: 0px; */
}
.buttonrow{
    font-size: 20px;
    padding: 10px;
    margin-left: 4pc;
}
.category-small-box {
background-color: #f2f2f2; 
}
.paddding{
padding: 12px;
}


.daterangepicker td.active.end-date.in-range.available, .qtyTotal, .mm-menu em.mm-counter, .option-set li a.selected, .category-small-box:hover, .pricing-list-container h4:after, #backtotop a, .chosen-container-multi .chosen-choices li.search-choice, .select-options li:hover, button.panel-apply, .layout-switcher a:hover, .listing-features.checkboxes li:before, .comment-by a.reply:hover, .add-review-photos:hover, .office-address h3:after, .post-img:before, button.button, input[type="button"], input[type="submit"], a.button, a.button.border:hover, table.basic-table th, .plan.featured .plan-price, mark.color, .style-4 .tabs-nav li.active a, .style-5 .tabs-nav li.active a, .dashboard-list-box .button.gray:hover, .change-photo-btn:hover, .dashboard-list-box a.rate-review:hover, input:checked + .slider, .add-pricing-submenu.button:hover, .add-pricing-list-item.button:hover, .custom-zoom-in:hover, .custom-zoom-out:hover, #geoLocation:hover, #streetView:hover, #scrollEnabling:hover, #scrollEnabling.enabled, #mapnav-buttons a:hover, #sign-in-dialog .mfp-close:hover, #small-dialog .mfp-close:hover {
background-color: #4a81e6;
}
#navigation.style-1>ul>li:hover>a, #navigation.style-1>ul>li>a.current {
background: rgba(106, 147, 245, .09);
color: #4a81e6;
}
#navigation.style-1 .current, #navigation.style-1 ul li:hover a, body #navigation.style-1 ul li:hover ul li:hover a, body #navigation.style-1 ul li:hover ul li:hover li:hover a, body #navigation.style-1 ul li:hover ul li:hover li:hover li:hover a, body #navigation.style-1 ul ul li:hover a:after, body #navigation.style-1 ul ul li:hover ul li a:hover, body .user-menu ul li a:hover {
color: #4a81e6;
}
.comment-by-listing a:hover, .browse-all-user-listings a i, .hosted-by-title h4 a:hover, .style-2 .trigger.active a, .style-2 .ui-accordion .ui-accordion-header-active:hover, .style-2 .ui-accordion .ui-accordion-header-active, #posts-nav li a:hover, .plan.featured .listing-badge, .post-content h3 a:hover, .add-review-photos i, .show-more-button i, .listing-details-sidebar li a, .star-rating .rating-counter a:hover, .more-search-options-trigger:after, .header-widget .sign-in:hover, #footer a, #footer .footer-links li a:hover, #navigation.style-1 .current, #navigation.style-1 ul li:hover a, .user-menu.active .user-name:after, .user-menu:hover .user-name:after, .user-menu.active .user-name, .user-menu:hover .user-name, .main-search-input-item.location a:hover, .chosen-container .chosen-results li.highlighted, .input-with-icon.location a i:hover, .sort-by .chosen-container-single .chosen-single div:after, .sort-by .chosen-container-single .chosen-default, .panel-dropdown a:after, .post-content a.read-more, .post-meta li a:hover, .widget-text h5 a:hover, .about-author a, a.button.border.white:hover, .icon-box-2 i, a.button.border, .style-2 .ui-accordion .ui-accordion-header:hover, .style-2 .trigger a:hover, .plan.featured .listing-badges .featured, .list-4 li:before, .list-3 li:before, .list-2 li:before, .list-1 li:before, .info-box h4, .testimonial-carousel .slick-slide.slick-active .testimonial:before, .sign-in-form .tabs-nav li a:hover, .sign-in-form .tabs-nav li.active a, .lost_password:hover a, #top-bar .social-icons li a:hover i, .listing-share .social-icons li a:hover i, .agent .social-icons li a:hover i, #footer .social-icons li a:hover i, .headline span i{
color: #4a81e6;
}
#header.cloned #logo img {
transform: translate3d(0,0,0);
max-width: 100%;
margin-top: 0px;
}

.footerlogmargin{
    margin-right: 8px;
    }
    

    /* #customers {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #customers td,
    #customers th {
        border: 1px solid #ddd;
        padding: 8px;
    }
    .paddding{
    padding: 10px;
} */

.metaData{
height: 492px;
background: #2c0346;
border-radius: 11px;
overflow: auto;
color: #adff2f;

padding: 0 0.9em;
}
::-webkit-scrollbar {
width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #888; 
border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555; 
}
.sqimage{
border-radius: 10px;
width: 45px;
height: 49px;
float: left;
margin-right: 10px;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {

border-top: 0px solid #ddd;
}
.fixTableHead {
  overflow-y: auto;
  height: 110px;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}






.messages-inbox .message-avatar {
    position: absolute;
    left: 0px;
    top: 15px;
    }
    .messages-inbox ul li {
    border-bottom: 0px solid #eaeaea;
}
    .messages-inbox ul li.unread, .messages-inbox ul li:hover {
    background-color: #fbfdff;
}
    .messages-inbox .message-by {
    margin-left: 45px;
    }
    .message-by-headline h5 {
    margin: 4px 0 0px 0;
    }
    .message-avatar img {
    width: 61%;
    height: 10%;
    }
    .messages-inbox ul li a {
        padding: 0px;
    }
	.accordion {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
    font-weight: 600;
  transition: 0.4s;
  border-top: 1px solid rgb(229, 232, 235);
}

.active, .accordion:hover {
  background-color: #f2f5fe;
}
.w40{
    width: 40% !important;

}
.heightoverflow{
    height: 198px;
    overflow: auto;
}
.w12{
    width: 12% !important;
}
.panel {
  padding: 0 18px;
  background-color: #fbfdff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
	
.slickcss{
    background-size: 1180px 418px;
	background-repeat: no-repeat, repeat !important;
}

.home-search-slider-headlines {
    padding-bottom: 112px !important;
}
.square {
  height: 150px;
  width: 150px;
  
}
.container2 {
  position: relative;
  text-align: center;
  color: white;
}
/* .centered {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
} */
.layout-switcher a.active {
    color: #79a1ed;
    border-color: #79a1ed;
}
.w10{
    width: 10% !important;
    margin-left: 10px;
}
